<template>
  <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5798 0.328125H2.25084C1.30268 0.328125 0.529358 1.11799 0.529358 2.06171V9.94939C0.529358 10.9178 1.30268 11.683 2.25084 11.683H4.83308V13.966C4.83308 14.2363 5.12949 14.3933 5.34737 14.2325L8.70696 11.6583H12.5803C13.5285 11.6583 14.3018 10.8684 14.3018 9.92467V2.06171C14.3013 1.11799 13.5508 0.328125 12.5798 0.328125ZM4.4675 5.16606C3.99006 5.16606 3.63097 5.55756 3.63097 6.04522C3.63097 6.53288 4.01427 6.92437 4.4675 6.92437C4.92074 6.92437 5.30404 6.53288 5.30404 6.04522C5.30404 5.55756 4.94629 5.16606 4.4675 5.16606ZM7.4793 5.16606C7.00185 5.16606 6.61882 5.55756 6.61882 6.04522C6.61882 6.53288 7.00212 6.92437 7.4793 6.92437C7.95674 6.92437 8.31583 6.53288 8.31583 6.04522C8.31583 5.55756 7.95809 5.16606 7.4793 5.16606ZM9.65618 6.04522C9.65618 5.55756 10.0153 5.16606 10.4927 5.16606C10.9715 5.16606 11.3292 5.55756 11.3292 6.04522C11.3292 6.53288 10.9459 6.92437 10.4927 6.92437C10.0395 6.92437 9.65618 6.53288 9.65618 6.04522ZM12.6048 10.4371C12.8401 10.4371 13.0351 10.2379 13.0351 9.9975V2.0851C13.0351 1.8447 12.8401 1.64552 12.6048 1.64552H2.27583C2.04047 1.64552 1.84546 1.8447 1.84546 2.0851V9.9975C1.84546 10.2379 2.04047 10.4371 2.27583 10.4371H6.14918V12.0855L8.30103 10.4371H12.6048Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'VegaChatIcon',
  props: {
    width: {
      type: Number,
      default: 15,
      required: false
    },
    height: {
      type: Number,
      default: 15,
      required: false
    },
    color: {
      type: String,
      default: '#fff',
      required: false
    }
  }
}
</script>

<template>
  <div class="vx-row pvw-vwidgets">
    <vs-prompt
      :title="$t('vue.deleteDialog')"
      @cancel="onCancelDeleteWidget"
      @accept="onDeleteVegaWidget(selectedVegaWidgetId)"
      @close="onCancelDeleteWidget"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt" v-if="selectedVegaWidget">
        <p>
          <strong>{{ selectedVegaWidget.name }}</strong>
        </p>
        {{ $t('vue.deleteDialogMessage') }}
      </div>
    </vs-prompt>

    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base" v-for="(widget, index) in widgets" :key="index">
      <div class="pvw-vwidgets-box">
        <div class="pvw-vwidgets-box--controls">
          <div class="pvw-vwidgets-box--controls--header">{{ widget.name }}</div>
          <div class="pvw-vwidgets-box--controls--switch" @click="toggleEnableVegaWidget(widget)">
            <toggle-switch-icon :enabled="widget.isVegaWidgetEnabled || false" :width="50" :height="50" :color="`${IS_ADMIN ? '#275D73' : '#979797'}`" />
          </div>
        </div>
        <div class="pvw-vwidgets-box--actions">
          <feather-icon
            icon="EditIcon"
            class="inline-flex rounded-full dialog__icon"
            :style="{ background: `${IS_ADMIN ? 'rgba(var(--vs-primary),.15)' : 'rgba(151, 151, 151,.15)'}`, color: `${IS_ADMIN ? '#275D73' : '#979797'}` }"
            @click="onEditVegaWidget(widget.id)"
          />
          <feather-icon
            icon="TrashIcon"
            class="inline-flex rounded-full dialog__icon"
            :style="{
              background: `${IS_ADMIN ? 'rgba(var(--vs-danger),.15)' : 'rgba(151, 151, 151,.15)'}`,
              color: `${IS_ADMIN ? 'rgba(234,84,85)' : '#979797'}`
            }"
            @click="onDeleteVegaWidgetPrompt(widget)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
export default {
  name: 'VegaWidgets',
  components: {
    ToggleSwitchIcon
  },

  data() {
    return {
      deletePrompt: false,
      deleteVegaWidgetId: null
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      selectedVegaWidgetId: 'selectedVegaWidgetId',
      selectedVegaWidget: 'selectedVegaWidget'
    }),

    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    widgets() {
      if (!this.dialogs) return []
      return this.dialogs.filter((x) => x.isVegaWidget)
    }
  },

  methods: {
    ...mapMutations({
      setSelectedVegaWidget: 'SET_SELECTED_VEGA_WIDGET',
      setSelectedVegaWidgetId: 'SET_SELECTED_VEGA_WIDGET_ID'
    }),
    async toggleEnableVegaWidget(widget) {
      if (!this.IS_ADMIN) return
      const isVegaWidgetEnabled = Boolean(!widget.isVegaWidgetEnabled)
      await this.$vs.loading()
      await this.$db.collection('dialogs').doc(widget.id).set({ isVegaWidgetEnabled }, { merge: true })
      await this.$vs.loading.close()
    },
    async onEditVegaWidget(id) {
      if (!this.IS_ADMIN) return
      await this.$vs.loading()
      const widgetRef = await this.$db.collection('dialogs').doc(id).get()
      const widget = widgetRef.data()
      if (widget) {
        this.setSelectedVegaWidgetId(id)
        this.setSelectedVegaWidget(widget)
        this.$emit('edit-widget')
      }

      await this.$vs.loading.close()
    },

    async onDeleteVegaWidgetPrompt(widget) {
      if (!this.IS_ADMIN) return
      this.setSelectedVegaWidgetId(widget.id)
      this.setSelectedVegaWidget(widget)
      this.deletePrompt = true
    },
    onCancelDeleteWidget() {
      this.setSelectedVegaWidgetId(-1)
      this.setSelectedVegaWidget(null)
    },

    async onDeleteVegaWidget(id) {
      if (!this.IS_ADMIN) return
      await this.$vs.loading()
      await this.$db.collection('dialogs').doc(id).set(
        {
          isVegaWidgetEnabled: this.$firebase.firestore.FieldValue.delete(),
          isVegaWidget: this.$firebase.firestore.FieldValue.delete(),
          vega: this.$firebase.firestore.FieldValue.delete()
        },
        { merge: true }
      )
      this.setSelectedVegaWidgetId(-1)
      this.setSelectedVegaWidget(null)
      this.$emit('delete-widget')

      await this.$vs.loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.pvw-vwidgets {
  position: relative;
  margin-top: 26px;

  &-box {
    @media (max-width: 768px) {
      max-width: 100%;
      max-height: 74px;
      margin-top: 15px;
    }
    @media (min-width: 769px) {
      max-height: 166px;
    }

    width: 100%;
    height: 100%;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 15px;

    &--controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &--header {
        font-family: 'Larsseit-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        color: #575757;

        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--actions {
      align-self: center;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  &-box:last-child {
    margin-right: 0px;
  }
}

.dialog {
  &__icon {
    margin: 36px 10px 20px;
    padding: 10px;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.rounded-full {
  border-radius: 9999px !important;
}
</style>

<template>
  <div class="pac-choose-wrapper">
    <div v-if="!widgets || widgets.length === 0" class="mt-5 pac-choose__hints">
      <vs-icon class="pac-choose__hints__icon" icon-pack="material-icons" icon="error" size="22px" color="rgb(240, 85, 65)" />

      {{ $t('info.vegaHintsTitle') }}

      <ul class="pac-choose__hints__options-list">
        <li class="pac-choose__hints__options-list__item">{{ $t('info.vegaHintsOption_1') }}</li>
        <li class="pac-choose__hints__options-list__item">{{ $t('info.vegaHintsOption_2') }}</li>
        <li class="pac-choose__hints__options-list__item">{{ $t('info.vegaHintsOption_3') }}</li>
      </ul>

      <div class="mt-5 vx-row">
        <div class="vx-col pl-5">
          <vs-button size="medium" color="primary" class="btn-main" @click.prevent="$router.push({ name: 'dialog', params: { action: 'add-web-connect' } })">
            {{ $t('vue.addWebConnect') }}
          </vs-button>
        </div>
      </div>
    </div>

    <div v-else class="pac-choose">
      <div class="pac-choose-select">
        <div class="pac-choose-select--label">{{ $t('vega.choose.selectLabel') }}</div>
        <div>
          <select class="pac-choose-select--input" v-validate="'requiredSelect'" name="widgets" v-model="selectedVegaWidget" @change="onValidate">
            <option disabled value="-1" selected>{{ $t('vega.choose.selectInput') }}</option>
            <option v-for="option in widgets" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
      <div class="pac-choose-preview">
        <div class="pac-choose-select--label">{{ $t('vega.choose.previewLabel') }}</div>
        <div v-if="selectedVegaWidget && selectedVegaWidget !== -1 && dialog" class="pac-choose-preview--preview">
          <button-plus-text-new-preview v-if="dialog && dialog.type === 'button' && dialog.useNewButtonPlusTextStyle" :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonPlusTextNewMedia'" :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonPlusTextNewMedia' : ''" :dialog="dialog" :imageURL="defaultImageURL" :embedVideoURL="embedVideoURL" />
          <button-preview v-else :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'buttonMedia'" :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'buttonMedia' : ''" :dialog="dialog" :isForPreviewScreen="false" :canShowMinimizeIcon="canShowMinimizeIcon" :imageURL="defaultImageURL" :embedVideoURL="embedVideoURL" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { Validator } from 'vee-validate'
import validationDictionary from '@/validations/validationDictionary'
import ButtonPreview from '@/components/dialog/media/ButtonPreview'
import ButtonPlusTextNewPreview from '@/components/dialog/media/ButtonPlusTextNewPreview'
export default {
  name: 'VegaStepChooseWidget',
  data() {
    return {
      selectedVegaWidget: -1,
      dialog: null,
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg')
    }
  },
  components: {
    ButtonPreview,
    ButtonPlusTextNewPreview
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs',
      selectedVegaWidgetId: 'selectedVegaWidgetId'
    }),

    canShowMinimizeIcon() {
      if (((this.dialog && !this.dialog.isAlwaysShowButtonEnabled) || this.dialog.isMinimizeSmallButtonEnabled) && !this.dialog.isSmallButtonPreviewVisible) {
        return true
      }
      return false
    },

    embedVideoURL() {
      return this.defaultVideoURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },

    widgets() {
      if (!this.dialogs || this.dialogs.length === 0) return []
      return this.dialogs.map((x) => {
        return {
          value: x.id,
          text: x.name
        }
      })
    }
  },

  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    if (this.selectedVegaWidgetId) {
      this.selectedVegaWidget = this.selectedVegaWidgetId
    }
  },
  methods: {
    ...mapMutations({
      setSelectedVegaWidget: 'SET_SELECTED_VEGA_WIDGET',
      setSelectedVegaWidgetId: 'SET_SELECTED_VEGA_WIDGET_ID'
    }),
    async onValidate() {
      this.formValidated = await this.$validator.validateAll(['widgets'])
    }
  },
  watch: {
    async selectedVegaWidget() {
      this.setSelectedVegaWidgetId(this.selectedVegaWidget)
      if (this.selectedVegaWidget && this.selectedVegaWidget !== -1) {
        const widgetRef = await this.$db.collection('dialogs').doc(this.selectedVegaWidget).get()
        if (widgetRef && widgetRef.data()) {
          this.dialog = widgetRef.data()
          this.setSelectedVegaWidget(widgetRef.data())
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pac-choose {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;

  &-wrapper {
    margin-top: 30px;
    width: 88vw;
    height: 90vh;

    @media (min-width: 1200px) {
      width: calc(100vw - 410px) !important;
    }

    max-height: 475px;
  }

  &__hints {
    position: relative;
    padding-left: 30px;

    &__icon {
      position: absolute;
      top: -1px;
      left: 0;
    }

    &__options-list {
      padding-left: 13px;

      &__item {
        position: relative;
        padding-left: 9px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          left: -3px;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: rgb(87, 87, 87);
        }
      }
    }
  }

  &-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 5;
    align-self: flex-start;

    &--label {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #575757;
    }

    &--input {
      width: 90%;
      margin-top: 30px;
      padding: 10px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -0.111111px;
      color: #878894;
      border-radius: 8px;

      background: #ffffff;
      border: 1px solid #d9dbdd;
    }
  }

  &-preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-self: flex-end;

    &--preview {
      margin-top: 20px;
      align-self: flex-start;
    }
  }
}
</style>

<template>
  <div class="vap-preview">
    <div v-if="!isVegaPopupWithoutImage" class="vap-preview--bubble">
      <VegaBubbleIcon />
    </div>
    <div class="vap-preview-box">
      <div class="vap-preview-box--close">
        <close-icon :width="15" :height="15" :color="step2ButtonForeColor" />
      </div>
      <div class="vap-preview-box--headline">{{ text }}</div>
      <div class="vap-preview-box--message" v-html="message"></div>
      <div v-if="dialog" class="vap-preview-box--action">
        <div v-if="!dialog.communicationOptions || dialog.communicationOptions.chat" class="vap-preview-box--action--btn" :style="step2ButtonColor">
          <div>
            <VegaChatIcon :width="15" :height="15" :color="dialog.step2IconColor" />
          </div>
          <div :style="step2IconColor">{{ $tp('buttonAndHints.chat') }}</div>
        </div>
        <div v-if="!dialog.communicationOptions || dialog.communicationOptions.audio" class="vap-preview-box--action--btn" :style="step2ButtonColor">
          <div>
            <VegaPhoneIcon :width="15" :height="14" :color="dialog.step2IconColor" />
          </div>
          <div :style="step2IconColor">{{ $tp('buttonAndHints.phone') }}</div>
        </div>

        <div v-if="!dialog.communicationOptions || dialog.communicationOptions.video" class="vap-preview-box--action--btn" :style="step2ButtonColor">
          <div>
            <VegaVideoIcon :width="17" :height="12" :color="dialog.step2IconColor" />
          </div>
          <div :style="step2IconColor">{{ $tp('buttonAndHints.video') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VegaBubbleIcon from './VegaBubbleIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon'
import VegaChatIcon from '@/components/icons/vega/VegaChatIcon.vue'
import VegaPhoneIcon from '@/components/icons/vega/VegaPhoneIcon.vue'
import VegaVideoIcon from '@/components/icons/vega/VegaVideoIcon.vue'

export default {
  name: 'VegaPreview',
  components: {
    VegaBubbleIcon,
    CloseIcon,
    VegaChatIcon,
    VegaPhoneIcon,
    VegaVideoIcon
  },

  props: {
    text: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      dialog: 'selectedVegaWidget',
      isVegaPopupWithoutImageData: 'isVegaPopupWithoutImage'
    }),
    step2ButtonForeColor() {
      return this.dialog && this.dialog.step2ButtonColor ? this.dialog.step2ButtonColor : '#000'
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor
      }
    },
    step2TextColor() {
      return {
        color: this.dialog.step2TextColor
      }
    },
    step2IconColor() {
      return {
        color: this.dialog.step2IconColor
      }
    },
    isVegaPopupWithoutImage() {
      return this.isVegaPopupWithoutImageData
    }
  }
}
</script>
<style lang="scss">
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-small {
  font-size: 0.75em;
}
</style>
<style lang="scss" scoped>
.vap-preview {
  position: relative;
  margin-top: 47.61px;
  margin-left: 25px;
  width: 100%;
  height: 100%;

  display: flex;

  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 264.16px;
    height: 157.39px;
    position: relative;

    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 3.08599px 4.93758px rgba(0, 0, 0, 0.25);
    border-radius: 19.1331px;

    margin-left: 0px;

    &--close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &--headline {
      align-self: flex-start;
      margin-left: 60px;
      font-family: 'Larsseit-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 17.2815px;
      line-height: 11px;
      letter-spacing: 0.0270024px;
      color: #53535b;
    }

    &--message {
      max-width: 185px;
      margin-top: 12px;
      align-self: flex-start;
      margin-left: 60px;
      font-family: 'Larsseit-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 9.87516px;
      line-height: 128.5%;
      letter-spacing: 0.0270024px;
      color: #53535b;
    }

    &--action {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 148.67px;
      max-width: 148.67px;
      margin-top: 12.27px;

      &--btn {
        min-width: 45.25px;
        min-height: 45.9px;
        width: 100%;
        margin-right: 6.46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 9.32439px;

        font-family: 'Larsseit-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 8.40399px;
        line-height: 14px;
        text-align: center;
        letter-spacing: -0.0466889px;
        cursor: pointer;
      }

      &--btn:last-child {
        margin-right: 0px;
      }
    }
  }

  &--bubble {
    position: absolute;
    top: -30px;
    left: -30px;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pac-choose-wrapper" }, [
    !_vm.widgets || _vm.widgets.length === 0
      ? _c(
          "div",
          { staticClass: "mt-5 pac-choose__hints" },
          [
            _c("vs-icon", {
              staticClass: "pac-choose__hints__icon",
              attrs: {
                "icon-pack": "material-icons",
                icon: "error",
                size: "22px",
                color: "rgb(240, 85, 65)",
              },
            }),
            _vm._v(
              "\n\n    " + _vm._s(_vm.$t("info.vegaHintsTitle")) + "\n\n    "
            ),
            _c("ul", { staticClass: "pac-choose__hints__options-list" }, [
              _c(
                "li",
                { staticClass: "pac-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("info.vegaHintsOption_1")))]
              ),
              _c(
                "li",
                { staticClass: "pac-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("info.vegaHintsOption_2")))]
              ),
              _c(
                "li",
                { staticClass: "pac-choose__hints__options-list__item" },
                [_vm._v(_vm._s(_vm.$t("info.vegaHintsOption_3")))]
              ),
            ]),
            _c("div", { staticClass: "mt-5 vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col pl-5" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "btn-main",
                      attrs: { size: "medium", color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$router.push({
                            name: "dialog",
                            params: { action: "add-web-connect" },
                          })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("vue.addWebConnect")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _c("div", { staticClass: "pac-choose" }, [
          _c("div", { staticClass: "pac-choose-select" }, [
            _c("div", { staticClass: "pac-choose-select--label" }, [
              _vm._v(_vm._s(_vm.$t("vega.choose.selectLabel"))),
            ]),
            _c("div", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "requiredSelect",
                      expression: "'requiredSelect'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedVegaWidget,
                      expression: "selectedVegaWidget",
                    },
                  ],
                  staticClass: "pac-choose-select--input",
                  attrs: { name: "widgets" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedVegaWidget = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onValidate,
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "", value: "-1", selected: "" } },
                    [_vm._v(_vm._s(_vm.$t("vega.choose.selectInput")))]
                  ),
                  _vm._l(_vm.widgets, function (option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [_vm._v(_vm._s(option.text))]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { staticClass: "pac-choose-preview" }, [
            _c("div", { staticClass: "pac-choose-select--label" }, [
              _vm._v(_vm._s(_vm.$t("vega.choose.previewLabel"))),
            ]),
            _vm.selectedVegaWidget &&
            _vm.selectedVegaWidget !== -1 &&
            _vm.dialog
              ? _c(
                  "div",
                  { staticClass: "pac-choose-preview--preview" },
                  [
                    _vm.dialog &&
                    _vm.dialog.type === "button" &&
                    _vm.dialog.useNewButtonPlusTextStyle
                      ? _c("button-plus-text-new-preview", {
                          attrs: {
                            uploadType: _vm.dialog.useOneMediaForAllSteps
                              ? "allMedia"
                              : "buttonPlusTextNewMedia",
                            uploadSubtype:
                              _vm.dialog.useOneMediaForAllSteps &&
                              typeof _vm.dialog.allMedia === "object" &&
                              _vm.dialog.allMedia !== null
                                ? "buttonPlusTextNewMedia"
                                : "",
                            dialog: _vm.dialog,
                            imageURL: _vm.defaultImageURL,
                            embedVideoURL: _vm.embedVideoURL,
                          },
                        })
                      : _c("button-preview", {
                          attrs: {
                            uploadType: _vm.dialog.useOneMediaForAllSteps
                              ? "allMedia"
                              : "buttonMedia",
                            uploadSubtype:
                              _vm.dialog.useOneMediaForAllSteps &&
                              typeof _vm.dialog.allMedia === "object" &&
                              _vm.dialog.allMedia !== null
                                ? "buttonMedia"
                                : "",
                            dialog: _vm.dialog,
                            isForPreviewScreen: false,
                            canShowMinimizeIcon: _vm.canShowMinimizeIcon,
                            imageURL: _vm.defaultImageURL,
                            embedVideoURL: _vm.embedVideoURL,
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paa-widget" },
    [
      _c(
        "form-wizard",
        {
          ref: "vegaFormWizard",
          staticClass: "paa-widget--formwizard",
          attrs: {
            color: "rgba(var(--vs-primary), 1)",
            title: "",
            subtitle: "",
            startIndex: _vm.currentStep,
            nextButtonText: _vm.$t("vue.next"),
            backButtonText: _vm.$t("vue.back"),
            finishButtonText: _vm.$t("inputs.submit"),
            shape: "circle",
          },
          on: { "on-complete": _vm.onComplete, "on-change": _vm.onStepChanged },
        },
        [
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("vega.tabs.howTo"),
                icon: "material-icons icon-question-mark",
                lazy: true,
              },
            },
            [_vm.currentStep == 0 ? _c("HowTo") : _vm._e()],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "tab2 mb-5",
              attrs: {
                title: _vm.$t("vega.tabs.chooseWidget"),
                icon: "material-icons icon-widgets",
                "before-change": _vm.validateChooseWidget,
                lazy: true,
              },
            },
            [_vm.currentStep == 1 ? _c("ChooseWidget") : _vm._e()],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("vega.tabs.popupText"),
                icon: "material-icons icon-title",
                "before-change": _vm.validateVegaPopup,
              },
            },
            [_vm.currentStep == 2 ? _c("PopupText") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
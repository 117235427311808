<template>
  <div class="pav-chat-bubble">
    <div class="pav-chat-bubble--onlineindicator"></div>
    <div class="pav-chat-bubble-lastborder" :style="bubbleBorderColor"></div>
    <div class="pav-chat-bubble-secondborder" :style="step2ButtonColorLight"></div>
    <div class="pav-chat-bubble-firstborder" :style="step2ButtonColor"></div>
    <div class="pav-chat-bubble-content">
      <video v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" playsinline autoplay muted loop>
        <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
        <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
      </video>
      <img v-else :src="mediaImg" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VegaBubbleIcon',
  data() {
    return {
      isOnline: true,
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      defaultVideoURL: '/Mann-Winken-SD.mp4',
      webm: 'video/webm',
      mp4: 'video/mp4'
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'selectedVegaWidget'
    }),

    step2ButtonColor() {
      return {
        backgroundColor: this.dialog && this.dialog.step2ButtonColor ? this.dialog.step2ButtonColor : '#000'
      }
    },

    bubbleBorderColor() {
      const originalColor = this.step2ButtonColor.backgroundColor
      const rgbaColor = this.hexToRgba(originalColor, 0.2)
      return {
        backgroundColor: rgbaColor
      }
    },

    step2ButtonColorLight() {
      const originalColor = this.step2ButtonColor.backgroundColor
      const rgbaColor = this.hexToRgba(originalColor, 0.6)
      return {
        backgroundColor: rgbaColor
      }
    },

    showDialogDefaultMedia() {
      if (this.dialog && this.dialog.showDefaultMedia) {
        return true
      }
      return false
    },
    uploadType() {
      const useNewButtonPlusTextStyle = this.dialog && this.dialog.useNewButtonPlusTextStyle

      return this.dialog && this.dialog.useOneMediaForAllSteps ? 'allMedia' : useNewButtonPlusTextStyle ? 'buttonPlusTextNewMedia' : 'buttonMedia'
    },
    uploadSubtype() {
      return this.dialog && this.dialog.useOneMediaForAllSteps && typeof this.dialog.allMedia === 'object' && this.dialog.allMedia !== null ? 'buttonMedia' : ''
    },

    mediaImg() {
      try {
        if (this.showDialogDefaultMedia) {
          return this.dialog.showVideo ? this.defaultVideoURL : this.defaultImageURL
        }

        if (this.dialog && this.dialog.type === 'button' && this.dialog.useNewButtonPlusTextStyle) {
          if (this.dialog) {
            if (this.uploadType) {
              if (this.uploadSubtype) {
                return this.dialog[this.uploadType][this.uploadSubtype]
              } else {
                return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.defaultImageURL
              }
            }
            return this.defaultImageURL
          }
          return this.dialog && this.dialog.showVideo ? this.defaultVideoURL : this.defaultImageURL
        } else {
          if (this.uploadType) {
            if (this.uploadSubtype) {
              return this.dialog[this.uploadType][this.uploadSubtype]
            } else {
              return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.defaultImageURL
            }
          }
          return this.defaultImageURL
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        return this.defaultImageURL
      }
    }
  },
  methods: {
    hexToRgba(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)
      return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }
  }
}
</script>
<style lang="scss" scoped>
.pav-chat-bubble {
  width: 85px;
  height: 99px;
  position: relative;
  z-index: 1;

  &--onlineindicator {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #2bff6f;
    border-radius: 50%;
    display: inline-block;
    z-index: 2;
    left: -4px;
    top: -4px;
  }

  &-lastborder {
    position: absolute;
    top: -2px;
    left: -3px;
    right: 0px;
    bottom: -2px;
    z-index: 0;
    clip-path: path(
      'M20.411 0C9.13829 0 -6.10352e-05 9.13832 -6.10352e-05 20.411V62.7412C-6.10352e-05 71.9354 6.07901 79.7097 14.437 82.2641L37.2829 100.279C39.6726 102.164 43.1826 100.461 43.1826 97.4181V83.1522H62.034C73.3068 83.1522 82.4451 74.0139 82.4451 62.7412V20.4111C82.4451 9.13835 73.3067 0 62.034 0H20.411Z'
    );

    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.3);
  }

  &-secondborder {
    position: absolute;
    top: -4px;
    left: -4px;
    right: 0px;
    bottom: -2px;
    z-index: 0;
    clip-path: path(
      'M20.411 0C9.13829 0 -6.10352e-05 9.13832 -6.10352e-05 20.411V62.7412C-6.10352e-05 71.9354 6.07901 79.7097 14.437 82.2641L37.2829 100.279C39.6726 102.164 43.1826 100.461 43.1826 97.4181V83.1522H62.034C73.3068 83.1522 82.4451 74.0139 82.4451 62.7412V20.4111C82.4451 9.13835 73.3067 0 62.034 0H20.411Z'
    );

    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.3);
  }

  &-firstborder {
    position: absolute;
    top: 2px;
    left: -2px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    clip-path: path(
      'M19.7587 0.304688C9.02458 0.304688 0.322876 9.00638 0.322876 19.7405V55.3063C0.322876 63.4602 5.34408 70.4414 12.4631 73.3264L33.3628 89.8069C35.6383 91.6012 38.9807 89.9804 38.9807 87.0825V74.7421H54.6915C65.4256 74.7421 74.1273 66.0404 74.1273 55.3063V19.7405C74.1273 9.00639 65.4256 0.304688 54.6915 0.304688H19.7587Z'
    );

    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.3);
  }

  &-content {
    video {
      position: relative;
      width: 75px;
      height: 91px;
      object-fit: cover;
      background-color: #f0f0f0;
      clip-path: path(
        'M19.7587 0.304688C9.02458 0.304688 0.322876 9.00638 0.322876 19.7405V55.3063C0.322876 63.4602 5.34408 70.4414 12.4631 73.3264L33.3628 89.8069C35.6383 91.6012 38.9807 89.9804 38.9807 87.0825V74.7421H54.6915C65.4256 74.7421 74.1273 66.0404 74.1273 55.3063V19.7405C74.1273 9.00639 65.4256 0.304688 54.6915 0.304688H19.7587Z'
      );
    }

    img {
      position: relative;
      width: 75px;
      height: 91px;
      object-fit: cover;
      background-color: #f0f0f0;
      clip-path: path(
        'M19.7587 0.304688C9.02458 0.304688 0.322876 9.00638 0.322876 19.7405V55.3063C0.322876 63.4602 5.34408 70.4414 12.4631 73.3264L33.3628 89.8069C35.6383 91.6012 38.9807 89.9804 38.9807 87.0825V74.7421H54.6915C65.4256 74.7421 74.1273 66.0404 74.1273 55.3063V19.7405C74.1273 9.00639 65.4256 0.304688 54.6915 0.304688H19.7587Z'
      );
    }
  }
}
</style>

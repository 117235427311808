<template>
  <div>
    <div v-if="activeUserInfo && company" class="pa-vega" :class="{ 'pa-vega--bannermargintop': canShowTrialBanner && !isMobileApp }">
      <div v-if="!isMobile" class="pa-vega-action">
        <div v-if="showActivateVega" class="pa-vega-action--add pa-vega-action--btn pa-vega-action--btn-pulse active" @click="onActivateVega">
          <div>{{ $t('vega.activateVegaNow') }}</div>
        </div>

        <div v-if="showActivateVegaTrial" class="pa-vega-action--add pa-vega-action--btn pa-vega-action--btn-pulse active" @click="onActivateVegaFreeTrial">
          <div>{{ $t('vega.activateVegaTrialNow') }}</div>
        </div>

        <div v-if="showConnected" class="pa-vega-action--connected pa-vega-action--btn">
          <div>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.5" cy="9.5" r="9.5" fill="#2BFF6F" />
              <path d="M13.6 7L8.375 12.7L6 10.1091" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>
            {{ $t('vega.connected') }}
          </div>
        </div>

        <div v-if="showConnectedInTrial" class="pa-vega-action--connected pa-vega-action--btn">
          <div>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.5" cy="9.5" r="9.5" fill="#2BFF6F" />
              <path d="M13.6 7L8.375 12.7L6 10.1091" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>
            {{ $t('vega.connectedInTrialMode') }}
            <span v-if="timeUntilExpiration" class="pa-vega-action--connected--timeleft">{{ timeLeftForExpiration }} </span>
          </div>
        </div>

        <div
          v-if="showConnected || showConnectedInTrial"
          class="pa-vega-action--add pa-vega-action--btn"
          :class="{ 'pa-vega-action--btn--disabled': !IS_ADMIN }"
          @click="onStartProcessToAddVegaToWidget"
        >
          <div>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 2.5V9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2.5 6H9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div>{{ $t('vega.addVegaToWidget') }}</div>
        </div>
      </div>
      <div class="pa-vega-content">
        <VegaGetStarted v-if="!showConnected && !showConnectedInTrial && !hasAddedVegaWidgets && !isGetStarted" @get-started="onGetStarted" />
        <VegaAnalysis v-if="(showConnected || showConnectedInTrial) && !hasAddedVegaWidgets && !processToAddVegaToWidgetStarted" />
        <AddVegaToWidget
          v-if="(showConnected || showConnectedInTrial) && processToAddVegaToWidgetStarted"
          :step="goToStep"
          @on-complete="onCompleteAddingVegaToWidget"
        />
        <VegaWidgets
          v-if="(showConnected || showConnectedInTrial) && hasAddedVegaWidgets && !processToAddVegaToWidgetStarted"
          @edit-widget="onEditWidget"
          @delete-widget="onDeleteWidget"
        />
      </div>
      <vega-message v-if="!HAS_ACCESS && !isMobileApp"></vega-message>
    </div>
    <vs-progress v-else indeterminate color="primary"></vs-progress>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { mobile_check } from '@/mixins/mobile_check'
import VegaGetStarted from './VegaGetStarted.vue'
import VegaAnalysis from './VegaAnalysis.vue'
import AddVegaToWidget from './AddVegaToWidget.vue'
import VegaWidgets from './VegaWidgets.vue'
import { Device } from '@capacitor/device'
import VegaMessage from '@/components/upgrade-messages/VegaMessage.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const duration = require('dayjs/plugin/duration')
dayjs.extend(utc)
dayjs.extend(duration)

export default {
  name: 'Vega',
  components: {
    VegaGetStarted,
    VegaAnalysis,
    AddVegaToWidget,
    VegaWidgets,
    VegaMessage
  },
  mixins: [mobile_check],
  data() {
    return {
      isGetStarted: false,
      processToAddVegaToWidgetStarted: false,
      isMobileApp: false,
      goToStep: 0
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('vega') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    IS_ADMIN() {
      if (!this.activeUserInfo) return false

      return this.activeUserInfo.role === 'admin'
    },

    canShowTrialBanner() {
      if (this.company && this.company.paymentStatus) {
        switch (this.company.paymentStatus) {
          case 'trial':
          case 'expired':
            return true

          case 'on-trial':
            return this.company && !this.company.currentInvoiceId && !this.company.mollieSubscriptionId
          default:
            return false
        }
      }
      return false
    },

    isMobile() {
      return this.mobileCheck()
    },

    showConnected() {
      return this.vegaIsActivated && !this.hasVegaTrialExpired
    },

    showConnectedInTrial() {
      return !this.vegaIsActivated && this.isVegaOnTrial && !this.hasVegaTrialExpired
    },

    hasVegaPaymentFailed() {
      if (this.company && this.company.vegaPaymentFailed) return true
      return false
    },

    showActivateVega() {
      if (this.hasVegaPaymentFailed) return false
      if (this.isVegaOnTrial && this.hasVegaTrialExpired) return true
      if (this.isVegaOnTrial) return false
      if (this.vegaIsActivated) return false
      return false
    },

    showActivateVegaTrial() {
      if (!this.company) return true
      if (this.hasVegaPaymentFailed) return false
      if (typeof this.company.vegaExpiryDate === 'undefined' && typeof this.company.vegaIsActivated === 'undefined') return true
      return false
    },

    hasVegaTrialExpired() {
      if (this.company && this.company.vegaIsOnTrial && this.company.vegaExpiryDate) {
        return this.company.vegaExpiryDate && dayjs().utc().isAfter(dayjs.unix(this.company.vegaExpiryDate.seconds).utc())
      }
      return false
    },

    timeLeftForExpiration() {
      if (this.timeUntilExpiration) {
        const now = dayjs().utc()
        const expiryDate = dayjs.unix(this.company.vegaExpiryDate.seconds).utc()

        const minutes = Math.ceil(expiryDate.diff(now, 'minute', true))
        if (minutes < 60) {
          return this.$i18n.t('vega.minutesLeft').replace('[numberOfMinutes]', minutes)
        }

        const hours = Math.ceil(minutes / 60)
        if (hours < 24) {
          return this.$i18n.t('vega.hoursLeft').replace('[numberOfHours]', hours)
        }

        const days = Math.ceil(hours / 24)
        return this.$i18n.t('vega.daysLeft').replace('[numberOfDays]', days)
      }
      return null
    },

    timeUntilExpiration() {
      if (!this.company || !this.company.vegaExpiryDate || !this.company.vegaIsOnTrial) return null

      if (this.company.vegaExpiryDate && dayjs().utc().isBefore(dayjs.unix(this.company.vegaExpiryDate.seconds).utc())) {
        return dayjs.unix(this.company.vegaExpiryDate.seconds).diff(dayjs(), 'seconds')
      }

      return null
    },

    isVegaOnTrial() {
      if (this.company && this.company.vegaIsOnTrial) return true
      return false
    },

    vegaIsActivated() {
      if (!this.company || !this.company.vegaIsActivated) return false

      if (this.company.vegaIsActivated) {
        return true
      }

      return false
    },

    hasAddedVegaWidgets() {
      if (!this.showConnected && !this.showConnectedInTrial) return false

      if (!this.dialogs || this.dialogs.length === 0) return false

      return this.dialogs.filter((x) => x.isVegaWidget).length > 0
    }
  },

  methods: {
    onActivateVega() {
      if (!this.IS_ADMIN) return
      this.$router.push({ name: 'payment', params: { action: 'activate-vega' } })
    },
    async onActivateVegaFreeTrial() {
      if (!this.IS_ADMIN) return
      await this.$vs.loading()
      await this.$db
        .collection('company')
        .doc(this.activeUserInfo.company)
        .set({ vegaIsOnTrial: true, vegaExpiryDate: dayjs().utc().add(10, 'days').toDate() }, { merge: true })
      await this.$vs.loading.close()
    },
    onEditWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddVegaToWidgetStarted = true
      this.goToStep = 2
    },
    onDeleteWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddVegaToWidgetStarted = false
      this.goToStep = 0
    },
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },
    onStartProcessToAddVegaToWidget() {
      if (!this.IS_ADMIN) return
      this.isGetStarted = true
      this.processToAddVegaToWidgetStarted = true
      this.goToStep = 0
    },
    onCompleteAddingVegaToWidget() {
      if (!this.IS_ADMIN) return
      this.processToAddVegaToWidgetStarted = false
      this.goToStep = 0
    },
    onGetStarted() {
      if (!this.IS_ADMIN) return
      this.isGetStarted = true
    }
  }
}
</script>
<style lang="scss">
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #275D73;
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #275D73;
    box-shadow: 0 0 0 0 rgba(40, 167, 249, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
    box-shadow: 0 0 0 15px rgba(40, 167, 249, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
    box-shadow: 0 0 0 0 rgba(40, 167, 249, 0);
  }
}
</style>
<style lang="scss" scoped>
.pa-vega {
  height: calc(100vh - 100px);
  position: relative;

  @media (min-width: 1200px) {
    width: calc(100vw - 325px) !important;
  }

  &--bannermargintop {
    margin-top: 120px;

    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }

  &-content {
    height: calc(100% - 65px);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  &-action {
    display: flex;

    &--btn {
      margin-right: 20px;

      &--disabled {
        background: #979797 !important;
      }

      &-pulse.active {
        box-shadow: 0 0 0 #275D73;
        background: #275D73;
        animation: pulse 2s infinite;
      }
      &-pulse.inactive {
        box-shadow: 0 0 0 rgba(181, 194, 184, 1);
        background: #95a098;
      }
    }
    &--btn:last-child {
      margin-right: 0px;
    }

    &--connected {
      padding: 0px 10px;
      height: 39px;
      background: #e6ecf1;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.111111px;
      color: #2bff6f;

      > div:last-child {
        margin-left: 10px;
      }

      &--timeleft {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding: 5px;
        background: #000;
        color: #fff;
        border-radius: 10px;
      }
    }

    &--add {
      padding: 13px 25px;
      height: 39px;
      background: #275D73;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.111111px;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
      }

      > div:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>

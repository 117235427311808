var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pavs-howto" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showBackground,
          expression: "showBackground",
        },
      ],
      staticClass: "video-background",
      style: "background-image: url(" + _vm.defaultImageURL + ");",
    }),
    _c("video", {
      ref: "videoPlayer",
      attrs: { src: _vm.videoUrl, preload: "none", controls: "" },
      on: { play: _vm.onPlay, pause: _vm.onPause, ended: _vm.onEnded },
    }),
    _c("img", {
      staticClass: "play-icon",
      class: { "play-icon--hidden": !_vm.showIcon },
      attrs: {
        "v-show": _vm.showIcon,
        src: require("@/assets/images/register-funnel/vega-video-icon.png"),
        alt: "Play Video",
      },
      on: { click: _vm.playHowTo },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
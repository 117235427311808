var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeUserInfo && _vm.company
        ? _c(
            "div",
            {
              staticClass: "pa-vega",
              class: {
                "pa-vega--bannermargintop":
                  _vm.canShowTrialBanner && !_vm.isMobileApp,
              },
            },
            [
              !_vm.isMobile
                ? _c("div", { staticClass: "pa-vega-action" }, [
                    _vm.showActivateVega
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-vega-action--add pa-vega-action--btn pa-vega-action--btn-pulse active",
                            on: { click: _vm.onActivateVega },
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("vega.activateVegaNow"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showActivateVegaTrial
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-vega-action--add pa-vega-action--btn pa-vega-action--btn-pulse active",
                            on: { click: _vm.onActivateVegaFreeTrial },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("vega.activateVegaTrialNow"))
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showConnected
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-vega-action--connected pa-vega-action--btn",
                          },
                          [
                            _c("div", [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "19",
                                    height: "19",
                                    viewBox: "0 0 19 19",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("circle", {
                                    attrs: {
                                      cx: "9.5",
                                      cy: "9.5",
                                      r: "9.5",
                                      fill: "#2BFF6F",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M13.6 7L8.375 12.7L6 10.1091",
                                      stroke: "white",
                                      "stroke-width": "1.9",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("vega.connected")) +
                                  "\n        "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showConnectedInTrial
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-vega-action--connected pa-vega-action--btn",
                          },
                          [
                            _c("div", [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "19",
                                    height: "19",
                                    viewBox: "0 0 19 19",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("circle", {
                                    attrs: {
                                      cx: "9.5",
                                      cy: "9.5",
                                      r: "9.5",
                                      fill: "#2BFF6F",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M13.6 7L8.375 12.7L6 10.1091",
                                      stroke: "white",
                                      "stroke-width": "1.9",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("vega.connectedInTrialMode")) +
                                  "\n          "
                              ),
                              _vm.timeUntilExpiration
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "pa-vega-action--connected--timeleft",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.timeLeftForExpiration) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.showConnected || _vm.showConnectedInTrial
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pa-vega-action--add pa-vega-action--btn",
                            class: {
                              "pa-vega-action--btn--disabled": !_vm.IS_ADMIN,
                            },
                            on: { click: _vm.onStartProcessToAddVegaToWidget },
                          },
                          [
                            _c("div", [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    viewBox: "0 0 12 12",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6 2.5V9.5",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M2.5 6H9.5",
                                      stroke: "white",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("vega.addVegaToWidget"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "pa-vega-content" },
                [
                  !_vm.showConnected &&
                  !_vm.showConnectedInTrial &&
                  !_vm.hasAddedVegaWidgets &&
                  !_vm.isGetStarted
                    ? _c("VegaGetStarted", {
                        on: { "get-started": _vm.onGetStarted },
                      })
                    : _vm._e(),
                  (_vm.showConnected || _vm.showConnectedInTrial) &&
                  !_vm.hasAddedVegaWidgets &&
                  !_vm.processToAddVegaToWidgetStarted
                    ? _c("VegaAnalysis")
                    : _vm._e(),
                  (_vm.showConnected || _vm.showConnectedInTrial) &&
                  _vm.processToAddVegaToWidgetStarted
                    ? _c("AddVegaToWidget", {
                        attrs: { step: _vm.goToStep },
                        on: { "on-complete": _vm.onCompleteAddingVegaToWidget },
                      })
                    : _vm._e(),
                  (_vm.showConnected || _vm.showConnectedInTrial) &&
                  _vm.hasAddedVegaWidgets &&
                  !_vm.processToAddVegaToWidgetStarted
                    ? _c("VegaWidgets", {
                        on: {
                          "edit-widget": _vm.onEditWidget,
                          "delete-widget": _vm.onDeleteWidget,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.HAS_ACCESS && !_vm.isMobileApp
                ? _c("vega-message")
                : _vm._e(),
            ],
            1
          )
        : _c("vs-progress", { attrs: { indeterminate: "", color: "primary" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
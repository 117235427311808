var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 15 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.773 7.89702L13.363 8.99097C13.9776 9.25082 14.3152 9.90563 14.1401 10.5454L13.5846 12.9188C13.4641 13.5383 12.911 13.972 12.2632 13.972C5.88291 13.972 0.694824 8.86079 0.694824 2.57513C0.694824 1.93694 1.1353 1.39205 1.76515 1.25017L4.17192 0.702938C4.81892 0.555605 5.48306 0.88457 5.75103 1.48741L6.86752 4.07261C7.10674 4.62739 6.94374 5.27908 6.47135 5.65897L5.58645 6.37303C6.26457 7.51245 7.25392 8.48687 8.43502 9.15597L9.15877 8.28549C9.5428 7.81624 10.2083 7.65586 10.773 7.89702ZM12.3752 12.6388L12.9315 10.2635C12.942 10.2116 12.913 10.157 12.8631 10.1126L10.2714 9.01891C10.2257 8.99778 10.1709 9.0116 10.1416 9.04814L9.08633 10.318C8.89986 10.5421 8.55724 10.6103 8.31722 10.4829C6.54372 9.62536 5.08647 8.18919 4.21581 6.44225C4.08641 6.1824 4.15564 5.86856 4.38313 5.68506L5.66934 4.66905C5.70891 4.63699 5.7221 4.58341 5.70231 4.53752L4.59084 1.98246C4.5727 1.94187 4.52984 1.91466 4.4841 1.91466C4.47586 1.91466 4.46721 1.91548 4.45896 1.91751L2.04981 2.46526C1.99582 2.47745 1.96161 2.52006 1.96161 2.57448C1.96161 8.17155 6.58262 12.724 12.2639 12.724C12.3196 12.724 12.3629 12.6674 12.3752 12.6388Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
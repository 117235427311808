var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pav-analysis" }, [
    _vm._m(0),
    _c("div", { staticClass: "pav-analysis--header" }, [
      _vm._v(_vm._s(_vm.$t("vega.analysis.header"))),
    ]),
    _c("div", { staticClass: "pav-analysis--description" }, [
      _vm._v(_vm._s(_vm.$t("vega.analysis.description"))),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pav-analysis--icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/register-funnel/vega-analysis.png"),
          width: "366",
          height: "287",
          alt: "Vega analysis",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <svg :width="width" :height="height" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8367 2.05764C15.5216 1.85237 15.1295 1.82507 14.7906 1.98314L11.9673 3.29657V2.76996C11.9673 1.79018 11.1844 0.996094 10.2184 0.996094H2.34843C1.38245 0.996094 0.599548 1.79018 0.599548 2.76996V9.86542C0.599548 10.8452 1.38245 11.6393 2.34843 11.6393H10.2184C11.1844 11.6393 11.9673 10.8452 11.9673 9.86542V9.33825L14.7901 10.6509C14.9361 10.7189 15.0915 10.7527 15.2461 10.7527C15.4519 10.7527 15.6566 10.6933 15.8361 10.5764C16.1509 10.3726 16.3395 10.0234 16.3395 9.61874V2.99169C16.3395 2.61197 16.1509 2.26552 15.8367 2.05764ZM10.6556 9.86404C10.6556 10.1086 10.4594 10.3075 10.2184 10.3075H2.3484C2.10732 10.3075 1.91118 10.1086 1.91118 9.86404V2.76858C1.91118 2.52407 2.10732 2.32512 2.3484 2.32512H10.2184C10.4594 2.32512 10.6556 2.52407 10.6556 2.76858V9.86404ZM11.9671 7.87463L15.0276 9.2965V3.33659L11.9671 4.75928V7.87463Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'VegaVideoIcon',
  props: {
    width: {
      type: Number,
      default: 17,
      required: false
    },
    height: {
      type: Number,
      default: 12,
      required: false
    },
    color: {
      type: String,
      default: '#fff',
      required: false
    }
  }
}
</script>

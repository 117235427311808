var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pav-chat-bubble" }, [
    _c("div", { staticClass: "pav-chat-bubble--onlineindicator" }),
    _c("div", {
      staticClass: "pav-chat-bubble-lastborder",
      style: _vm.bubbleBorderColor,
    }),
    _c("div", {
      staticClass: "pav-chat-bubble-secondborder",
      style: _vm.step2ButtonColorLight,
    }),
    _c("div", {
      staticClass: "pav-chat-bubble-firstborder",
      style: _vm.step2ButtonColor,
    }),
    _c("div", { staticClass: "pav-chat-bubble-content" }, [
      _vm.mediaImg.indexOf(".webm") > -1 || _vm.mediaImg.indexOf(".mp4") > -1
        ? _c(
            "video",
            {
              attrs: { playsinline: "", autoplay: "", muted: "", loop: "" },
              domProps: { muted: true },
            },
            [
              _vm.mediaImg.indexOf(".webm") > -1
                ? _c("source", { attrs: { src: _vm.mediaImg, type: _vm.webm } })
                : _vm._e(),
              _vm.mediaImg.indexOf(".mp4") > -1
                ? _c("source", { attrs: { src: _vm.mediaImg, type: _vm.mp4 } })
                : _vm._e(),
            ]
          )
        : _c("img", { attrs: { src: _vm.mediaImg } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }